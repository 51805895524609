import React from 'react';
import { Form } from 'react-bootstrap';
import './SearchBox.scss';

const SearchBox = (props) => {
  let { placeholder, type, value, name, onChange, iconVisible,onClear } = props
  return (
    <div className="SearchBox">
      <Form.Group controlId="formBasicEmail">
        <div className="inputBox">
          <Form.Control
            type={type}
            value={value}
            name={name}
            placeholder={placeholder}
            onChange={onChange} />
          {value !== "" && iconVisible && <i className={"icon-close_round pointerC"} onClick={onClear}></i>}
          {value === "" && iconVisible && <i className={"icon-search pointerC"}></i>}
        </div>
        {props.children}
      </Form.Group>
    </div>
  );
}

SearchBox.defaultProps = {
  iconVisible: true
}
export default SearchBox;
