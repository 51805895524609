import React, { useEffect, useState } from "react";
import './Share.scss';
import WebService from "../../util/webService";
import Constant from "../../util/constant";


const NotFound = (props) => {
  console.log(props.location.search.split('=')[1], 'PARAMS');
  let [imageLink, setImageLink] = useState("")
  const getDetail = async () => {
    const id = props.location.search.split('=')[1];
    await WebService.getWebServices(
      Constant.API.CLUB_DETAIL_BY_POST_ID,
      { oPostID: id },
      (response) => {
        if (response.status >= 200 || response.status <= 210) {
          let mainData = response.data;
          if (response.data.status === true) {
            setImageLink(mainData.sClubLogo)
          }
        }
      });
  }
  useEffect(() => {
    getDetail();
  }, [])
  return (
    <div className='nodataFound'>
      <div className="centerPart">
        <div className="imgSec">
          {imageLink && imageLink !== "" && <img src={imageLink} alt="txtlogo" fluid="true" />}
        </div>
      </div>
    </div>
  )
}



export default NotFound;