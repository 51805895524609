import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import "./CustomDropzone.scss";
const filesize = 5;
const img = {
  display: "block",
  width: 191,
  height: '100%'
};
function getImageSizeInBytesFromUrl(imgURL) {
  var request = new XMLHttpRequest();
  request.open("HEAD", imgURL, false);
  request.send(null);
  var headerText = request.getAllResponseHeaders();
  var re = /Content\-Length\s*:\s*(\d+)/i;
  re.exec(headerText);
  return parseInt(RegExp.$1);
}
function GetFilename(url) {
  if (url) {
    return url.split("/").pop();
  }
  return "";
}
function bytesToSize(bytes, decimals = 0) {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}
function fileSizeExceeded(bytes) {
  // return bytes > 5242880 ? true : false;
  return bytes > 1024 * 1024 * filesize ? true : false;
}
function CustomDropzone(props) {
  let {
    register,
    errors,
    name,
    maxFileSize,
    multiple,
    maxFiles,
    subType2,
  } = props;

  const [files, setFiles] = useState([]);
  const [largeFiles, setLargeFiles] = useState([]);
  const [smallFiles, setSmallFiles] = useState([]);
  const [imgDetail, setImgDetail] = useState({ type: "", height: 0, width: 0 });
  const [imageError, setImageError] = useState("");
  // let newLoog = getLogoBasedOnKey(props)
  const [sLogo, setLogo] = useState("");
  const [sLogoSmallMAX, setLogoSmallMAX] = useState("");
  const [sLogoSmallX, setLogoSmallX] = useState("");
  const [sLogoLargeMAX, setLogoLargeMAX] = useState("");
  const [sLogoLargeX, setLogoLargeX] = useState("");
  useEffect(() => {
    let logo = getLogoBasedOnKey(props);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png, image/jpg",
    style: { display: "block" },
    ref: register,
    name: name,
    multiple: multiple,
    maxSize: maxFileSize,
    maxFiles: maxFiles,
    onDrop: (acceptedFiles) => {
      setImageError("");
      if (
        props.type === "sponserBannerDropzone" ||
        props.type === "sponserSquareDropzone"
      ) {
        let file = acceptedFiles[0];
        if (!file) {
          setImageError(
            "Either the image is invalid or corrupted, please choose another one."
          );
        } else if (
          // !file.type.includes(["image/jpg", "image/jpeg", "image/png"])
          file.type !== "image/jpg" &&
          file.type !== "image/jpeg" &&
          file.type !== "image/png"
        ) {
          setImageError("Only JPG, JPEG or PNG images are allowed.");
        } else if (fileSizeExceeded(file.size)) {
          setImageError(
            "Image size is larger than " +
            filesize +
            " MB, maximum " +
            filesize +
            " MB allowed."
          );
        } else {
          var blob = URL.createObjectURL(file);
          var img = new Image();
          img.src = blob;
          img.onload = function () {
            var w = img.width;
            var h = img.height;
            // debugger;
            setImageError("");
            if (props.height !== h || props.width !== w) {
              setImageError(
                `Please upload only ${props.width} x ${props.height} image`
              );
            } else {
              setImgDetail({ type: props.subType, height: h, width: w });
              props.onChange(acceptedFiles[0]);
              if (subType2 === "small") {
                setSmallFiles(
                  acceptedFiles.map((file) =>
                    Object.assign(file, {
                      preview: URL.createObjectURL(file),
                    })
                  )
                );
              } else if (subType2 === "large") {
                setLargeFiles(
                  acceptedFiles.map((file) =>
                    Object.assign(file, {
                      preview: URL.createObjectURL(file),
                    })
                  )
                );
              } else {
                setFiles(
                  acceptedFiles.map((file) =>
                    Object.assign(file, {
                      preview: URL.createObjectURL(file),
                    })
                  )
                );
              }
            }
          };
        }
      } else {
        var file = acceptedFiles[0];
        if (!file) {
          setImageError(
            "Either the image is invalid or corrupted, please choose another one."
          );
        } else if (
          // !file.type.includes(["image/jpg", "image/jpeg", "image/png"])
          file.type !== "image/jpg" &&
          file.type !== "image/jpeg" &&
          file.type !== "image/png"
        ) {
          setImageError("Only JPG, JPEG or PNG images are allowed.");
        } else if (fileSizeExceeded(file.size)) {
          setImageError(
            "Image size is larger than " +
            filesize +
            " MB, maximum " +
            filesize +
            " MB allowed."
          );
        } else {
          props.onChange(file);
          setFiles(
            acceptedFiles.map((file) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file),
              })
            )
          );
        }
      }
    },
  });

  const getLogoBasedOnKey = (props) => {
    var sLogo = "";
    if (props.sFixturesLogo) {
      sLogo = props.sFixturesLogo;
    } else if (props.sClubLogo) {
      sLogo = props.sClubLogo;
    } else if (props.editDetail && Object.keys(props.editDetail).length > 0) {

      if (typeof props.subType2 !== "undefined") {
        setLogoSmallMAX(props.editDetail?.fSponsorLogoSmallMAX !== null ? props.editDetail?.fSponsorLogoSmallMAX : "");
        setLogoSmallX(props.editDetail?.fSponsorLogoSmallX !== null ? props.editDetail?.fSponsorLogoSmallX : "");
        setLogoLargeMAX(props.editDetail?.fSponsorLogoLargeMAX !== null ? props.editDetail?.fSponsorLogoLargeMAX : "");
        setLogoLargeX(props.editDetail?.fSponsorLogoLargeX !== null ? props.editDetail?.fSponsorLogoLargeX : "");
      } else {
        if (props.subType && props.subType === "medium-max") {
          sLogo = props.editDetail?.fSponsorLogoMediumMAX;
        } else if (props.subType && props.subType === "medium-x") {
          sLogo = props.editDetail?.fSponsorLogoMediumX;
        } else if (props.subType && props.subType === "max") {
          sLogo = props.editDetail?.fSponsorBannerMAX;
        } else if (props.subType && props.subType === "x") {
          sLogo = props.editDetail?.fSponsorBannerX;
        }
      }
    }
    setLogo(sLogo);
  };
  const hasError = errors[name] !== undefined;

  // setLogo(sLogo)
  const onDeleteBanner = (index) => {
    const newFiles = files.slice();
    newFiles.splice(index, 1);
    setFiles(newFiles);
    setImageError("");
  };
  const thumbsMobileView = files.map((file, index) => (
    <div className="boxZoneImgView">
      {/* <a
        className="closeIcon"
        onClick={(e) => {
          e.stopPropagation();
          setFiles([]);
          setLogo("");
          setImgDetail({ type: "", height: 0, width: 0 });
          setImageError("");
        }}
      >
        <i className="icon-close"></i>
      </a> */}
      <img key={index} src={file.preview} style={img} alt="thumb" />
    </div>
  ));
  const thumbsLargeFiles = largeFiles.map((lf, index) => (
    <div className="boxZoneImgView">
      <a
        className="closeIcon large"
        onClick={(e) => {
          e.stopPropagation();
          setLargeFiles([]);
          setImgDetail({ type: "", height: 0, width: 0 });
          setImageError("");
        }}
      >
        <i className="icon-close"></i>
      </a>
      <img key={index} src={lf.preview} style={img} alt="thumb" />
    </div>
  ));
  const thumbsSmallFiles = smallFiles.map((sf, index) => (
    <div className="boxZoneImgView">
      <a
        className="closeIcon small"
        onClick={(e) => {
          e.stopPropagation();
          setSmallFiles([]);
          setImgDetail({ type: "", height: 0, width: 0 });
          setImageError("");
        }}
      >
        <i className="icon-close"></i>
      </a>
      <img key={index} src={sf.preview} style={img} alt="thumb" />
    </div>
  ));

  const thumbs =
    props.type === "sponserBannerDropzone"
      ? files.map((file, index) => (
        <>
          <div className="bannerImgRow" key={index}>
            <div className="iconRealImg">
              <img
                // src={sLogo === "" ? file.preview : sLogo}
                src={file.preview ? file.preview : sLogo}
                className="bannerImg"
                alt="Preview"
              />
            </div>
          </div>
        </>
      ))
      : files.map((file, index) => (
        <img key={index} src={file.preview} style={img} alt="Preview" />
      ));
  return (
    <>
      {props.type === "clubDropzone" && (
        <div className="dropzone-wrapper">
          <div className="uploadArea">
            <div className="uploadview">
              <section className="containerDropzone">
                <div {...getRootProps({ className: "txtDropzone" })}>
                  <input {...getInputProps()} alt="thumb" />
                  {sLogo === "" && thumbs.length === 0 && (
                    <div className="uploadZone">
                      <div className="iconSec">
                        <i className="icon-imageThumb icon"></i>
                        <i className="icon-pluse plus" />
                      </div>
                      <span className="divider"></span>
                      <div className="txtSec">
                        <span className="whiteTxt">
                          Drag & Drop file here or <a>browse</a>
                        </span>
                      </div>
                      <div className="bgOverlay" />
                      <div className="dottedOverlay" />
                    </div>
                  )}
                  {sLogo !== "" && thumbs.length === 0 && (
                    <div className="imgBack">
                      <img src={sLogo} alt="thumb" />
                      <span className="editTXTView">EDIT</span>
                    </div>
                  )}
                  {thumbs.length > 0 && (
                    <div className="imgBack">
                      {thumbs}
                      <div className="editTXTView">EDIT</div>
                    </div>
                  )}
                </div>
              </section>
            </div>
          </div>
          <div className={hasError ? "errorMsgupload show" : "errorMsgupload"}>
            {props.errors[name]?.message
              ? props.errors[name]?.message
              : "error"}
          </div>
          <div
            className={
              imageError !== "" ? "errorMsgupload show" : "errorMsgupload"
            }
          >
            {imageError}
          </div>
        </div>
      )}

      {(props.type === "profileImgDropzone" ||
        props.type === "sponserLogoDropzone") && (
          <div className="partitionView">
            <div className="uploadArea">
              <section className="containerDropzone">
                <div {...getRootProps({ className: "txtDropzone" })}>
                  <input {...getInputProps()} />
                  {props.sProfilePic === "" && thumbs.length === 0 && (
                    <div className="greyBack">
                      <div className="circlegrey">
                        {props.circleIcon ? (
                          <i className={props.circleIcon} />
                        ) : (
                            <p className="icongreyTxt">ICON</p>
                          )}
                      </div>
                    </div>
                  )}
                  {props.sProfilePic !== "" && thumbs.length === 0 && (
                    <div className="greyBack">
                      <img src={props.sProfilePic.replace("upload/","upload/w_120,c_scale/")} alt="thumb" />
                      <div className="editView">
                        <i className="icon-edit_pencil" />
                      </div>
                    </div>
                  )}
                  {thumbs.length > 0 && (
                    <div className="greyBack">
                      {thumbs}
                      <div className="editView">
                        <i className="icon-edit_pencil" />
                      </div>
                    </div>
                  )}
                  <div className="txtSec">
                    <span className="blueTxt">{props.uploadTitle}</span>
                    <span className="whiteTxt">
                      Drop the file to the field on the left or{" "}
                      <a>browse a file.</a>
                    </span>
                    <span className="whiteTxt">Only up to 5 MB</span>
                  </div>
                </div>
              </section>
              <div
                className={hasError ? "errorMsgupload show" : "errorMsgupload"}
              >
                {props.errors[name]?.message
                  ? props.errors[name]?.message
                  : "error"}
              </div>
              <div
                className={
                  imageError !== "" ? "errorMsgupload show" : "errorMsgupload"
                }
              >
                {imageError}
              </div>
            </div>
          </div>
        )}

      {/*props.type === "sponserSquareDropzone" && props.subType==='small-max' && (
        <>
          <div className="tabletUploadArea">
            <section
              className="containerDropzone"
              style={{ height: props.secHeight }}
            >
              <div className="allBoxView">
                <div {...getRootProps({ className: "touchBox" })}>
                  <input {...getInputProps()} />

                  {typeof subType2 === "undefined" && sLogo === "" && (
                    <div className="uploadBox">
                      <i className="icon-imageUpload 111" />
                      <a className="blueTxt">Add image</a>
                    </div>
                  )}
                  {subType2 === "small" &&
                    thumbsSmallFiles.length === 0 &&
                    sLogoSmallMAX === "" && (
                      <div className="uploadBox">
                        <i className="icon-imageUpload 222" />
                        <a className="blueTxt">Add image</a>
                      </div>
                    )}

                  {subType2 === "small" &&
                    thumbsSmallFiles.length === 0 &&
                    sLogoSmallX === "" && (
                      <div className="uploadBox">
                        <i className="icon-imageUpload 333" />
                        <a className="blueTxt">Add image</a>
                      </div>
                    )}
                  {subType2 === "large" &&
                    thumbsLargeFiles.length === 0 &&
                    sLogoLargeMAX === "" && (
                      <div className="uploadBox">
                        <i className="icon-imageUpload 444" />
                        <a className="blueTxt">Add image</a>
                      </div>
                    )}
                  {subType2 === "large" &&
                    thumbsLargeFiles.length === 0 &&
                    sLogoLargeX === "" && (
                      <div className="uploadBox">
                        <i className="icon-imageUpload 555" />
                        <a className="blueTxt">Add image</a>
                      </div>
                    )}
                  {typeof subType2 === "undefined" &&
                    sLogo !== "" &&
                    thumbsMobileView.length === 0 && (
                      <div className="boxZoneImgView">
                        <a
                          className="closeIcon"
                          onClick={(e) => {
                            e.stopPropagation();
                            if (subType2 === "small") {
                              setSmallFiles([]);
                            } else if (subType2 === "large") {
                              setLargeFiles([]);
                            } else {
                              setFiles([]);
                            }
                            setLogo("");
                            setImgDetail({ type: "", height: 0, width: 0 });
                            setImageError("");
                          }}
                        >
                          <i className="icon-close"></i>
                        </a>
                        <img src={sLogo} style={img} alt="thumb" />
                      </div>
                    )}
                  {subType2 === "small" &&
                    sLogoSmallMAX !== "" &&
                    thumbsSmallFiles.length === 0 && (
                      <div className="boxZoneImgView small-max">
                        <a
                          className="closeIcon"
                          onClick={(e) => {
                            e.stopPropagation();
                            if (subType2 === "small") {
                              setSmallFiles([]);
                            } else if (subType2 === "large") {
                              setLargeFiles([]);
                            } else {
                              setFiles([]);
                            }
                            setLogo("");
                            setImgDetail({ type: "", height: 0, width: 0 });
                            setImageError("");
                          }}
                        >
                          <i className="icon-close"></i>
                        </a>
                        <img src={sLogoSmallMAX} style={img} alt="thumb" />
                      </div>
                    )}
                  {subType2 === "small" &&
                    sLogoSmallX !== "" &&
                    thumbsSmallFiles.length === 0 && (
                      <div className="boxZoneImgView small-x">
                        <a
                          className="closeIcon"
                          onClick={(e) => {
                            e.stopPropagation();
                            if (subType2 === "small") {
                              setSmallFiles([]);
                            } else if (subType2 === "large") {
                              setLargeFiles([]);
                            } else {
                              setFiles([]);
                            }
                            setLogo("");
                            setImgDetail({ type: "", height: 0, width: 0 });
                            setImageError("");
                          }}
                        >
                          <i className="icon-close"></i>
                        </a>
                        <img src={sLogoSmallX} style={img} alt="thumb" />
                      </div>
                    )}
                  {subType2 === "large" &&
                    sLogoLargeMAX !== "" &&
                    thumbsLargeFiles.length === 0 && (
                      <div className="boxZoneImgView large-max">
                        <a
                          className="closeIcon"
                          onClick={(e) => {
                            e.stopPropagation();
                            if (subType2 === "small") {
                              setSmallFiles([]);
                            } else if (subType2 === "large") {
                              setLargeFiles([]);
                            } else {
                              setFiles([]);
                            }
                            setLogo("");
                            setImgDetail({ type: "", height: 0, width: 0 });
                            setImageError("");
                          }}
                        >
                          <i className="icon-close"></i>
                        </a>
                        <img src={sLogoLargeMAX} style={img} alt="thumb" />
                      </div>
                    )}
                  {subType2 === "large" &&
                    sLogoLargeX !== "" &&
                    thumbsLargeFiles.length === 0 && (
                      <div className="boxZoneImgView large-x">
                        <a
                          className="closeIcon"
                          onClick={(e) => {
                            e.stopPropagation();
                            if (subType2 === "small") {
                              setSmallFiles([]);
                            } else if (subType2 === "large") {
                              setLargeFiles([]);
                            } else {
                              setFiles([]);
                            }
                            setLogo("");
                            setImgDetail({ type: "", height: 0, width: 0 });
                            setImageError("");
                          }}
                        >
                          <i className="icon-close"></i>
                        </a>
                        <img src={sLogoLargeX} style={img} alt="thumb" />
                      </div>
                    )}
                  {typeof subType2 === "undefined" &&
                    thumbsMobileView.length > 0 &&
                    thumbsMobileView}
                  {subType2 === "small" &&
                    thumbsSmallFiles.length > 0 &&
                    thumbsSmallFiles}
                  {subType2 === "large" &&
                    thumbsLargeFiles.length > 0 &&
                    thumbsLargeFiles}
                </div>
              </div>
            </section>
            <div
              className={hasError ? "errorMsgupload show" : "errorMsgupload"}
            >
              {props.errors[name]?.message
                ? props.errors[name]?.message
                : "error"}
            </div>
            <div
              className={
                imageError !== "" ? "errorMsgupload show" : "errorMsgupload"
              }
            >
              {imageError}
            </div>
          </div>
        </>
            )*/}
      {props.type === "sponserSquareDropzone" && (props.subType === 'medium-max' || props.subType === 'medium-x') && (
        <>
          <div className="tabletUploadArea">
            <section
              className="containerDropzone"
              style={{ width: props.secWidth }}
            >
              <div className="allBoxView">
                <div {...getRootProps({ className: "touchBox" })}>
                  <input {...getInputProps()} />
                  {thumbsMobileView.length === 0 &&
                    sLogo === "" && (
                      <div className="uploadBox" style={{ height: props.greySecMinHeight }}>
                        <i className="icon-imageUpload 222" />
                        <a className="blueTxt">Add image</a>
                      </div>
                    )}
                  {sLogo !== "" &&
                    thumbsMobileView.length === 0 && (
                      <div className="boxZoneImgView small-max">
                        {/* <a
                          className="closeIcon"
                          onClick={(e) => {
                            e.stopPropagation();
                            props.deleteImage(props.subType);
                            setFiles([]);
                            setLogo("");
                            setImgDetail({ type: "", height: 0, width: 0 });
                            setImageError("");
                          }}
                        >
                          <i className="icon-close"></i>
                        </a> */}
                        <img src={sLogo} style={img} alt="thumb" />
                      </div>
                    )}

                  {
                    thumbsMobileView.length > 0 &&
                    thumbsMobileView}
                </div>
              </div>
            </section>
            <div
              className={hasError ? "errorMsgupload show" : "errorMsgupload"}
            >
              {props.errors[name]?.message
                ? props.errors[name]?.message
                : "error"}
            </div>
            <div
              className={
                imageError !== "" ? "errorMsgupload show" : "errorMsgupload"
              }
            >
              {imageError}
            </div>
          </div>
        </>
      )}
      {props.type === "sponserSquareDropzone" && props.subType === 'small-max' && (
        <>
          <div className="tabletUploadArea">
            <section
              className="containerDropzone"
              style={{ width: props.secWidth }}
            >
              <div className="allBoxView">
                <div {...getRootProps({ className: "touchBox" })}>
                  <input {...getInputProps()} />
                  {subType2 === "small" &&
                    thumbsSmallFiles.length === 0 &&
                    sLogoSmallMAX === "" && (
                      <div className="uploadBox" style={{ height: props.greySecMinHeight }}>
                        <i className="icon-imageUpload 222" />
                        <a className="blueTxt">Add image</a>
                      </div>
                    )}
                  {subType2 === "small" &&
                    sLogoSmallMAX !== "" &&
                    thumbsSmallFiles.length === 0 && (
                      <div className="boxZoneImgView small-max">
                        <a
                          className="closeIcon"
                          onClick={(e) => {
                            e.stopPropagation();
                            props.deleteImage(props.subType);
                            setSmallFiles([]);
                            setLogoSmallMAX("");
                            setImgDetail({ type: "", height: 0, width: 0 });
                            setImageError("");
                          }}
                        >
                          <i className="icon-close"></i>
                        </a>
                        <img src={sLogoSmallMAX} style={img} alt="thumb" />
                      </div>
                    )}

                  {subType2 === "small" &&
                    thumbsSmallFiles.length > 0 &&
                    thumbsSmallFiles}
                </div>
              </div>
            </section>
            <div
              className={hasError ? "errorMsgupload show" : "errorMsgupload"}
            >
              {props.errors[name]?.message
                ? props.errors[name]?.message
                : "error"}
            </div>
            <div
              className={
                imageError !== "" ? "errorMsgupload show" : "errorMsgupload"
              }
            >
              {imageError}
            </div>
          </div>
        </>
      )}
      {props.type === "sponserSquareDropzone" && props.subType === 'small-x' && (
        <>
          <div className="tabletUploadArea">
            <section
              className="containerDropzone"
              style={{ width: props.secWidth }}
            >
              <div className="allBoxView">
                <div {...getRootProps({ className: "touchBox" })}>
                  <input {...getInputProps()} />
                  {subType2 === "small" &&
                    thumbsSmallFiles.length === 0 &&
                    sLogoSmallX === "" && (
                      <div className="uploadBox" style={{ height: props.greySecMinHeight }}>
                        <i className="icon-imageUpload" />
                        <a className="blueTxt">Add image</a>
                      </div>
                    )}
                  {subType2 === "small" &&
                    sLogoSmallX !== "" &&
                    thumbsSmallFiles.length === 0 && (
                      <div className="boxZoneImgView">
                        <a
                          className="closeIcon"
                          onClick={(e) => {
                            e.stopPropagation();
                            props.deleteImage(props.subType);
                            setSmallFiles([]);
                            setLogoSmallX("");
                            setImgDetail({ type: "", height: 0, width: 0 });
                            setImageError("");
                          }}
                        >
                          <i className="icon-close"></i>
                        </a>
                        <img src={sLogoSmallX} style={img} alt="thumb" />
                      </div>
                    )}

                  {subType2 === "small" &&
                    thumbsSmallFiles.length > 0 &&
                    thumbsSmallFiles}
                </div>
              </div>
            </section>
            <div
              className={hasError ? "errorMsgupload show" : "errorMsgupload"}
            >
              {props.errors[name]?.message
                ? props.errors[name]?.message
                : "error"}
            </div>
            <div
              className={
                imageError !== "" ? "errorMsgupload show" : "errorMsgupload"
              }
            >
              {imageError}
            </div>
          </div>
        </>
      )}
      {props.type === "sponserSquareDropzone" && props.subType === 'large-max' && (
        <>
          <div className="tabletUploadArea">
            <section
              className="containerDropzone"
              style={{ width: props.secWidth }}
            >
              <div className="allBoxView">
                <div {...getRootProps({ className: "touchBox" })}>
                  <input {...getInputProps()} />
                  {thumbsLargeFiles.length === 0 &&
                    sLogoLargeMAX === "" && (
                      <div className="uploadBox" style={{ height: props.greySecMinHeight }}>
                        <i className="icon-imageUpload 222" />
                        <a className="blueTxt">Add image</a>
                      </div>
                    )}
                  {sLogoLargeMAX !== "" &&
                    thumbsLargeFiles.length === 0 && (
                      <div className="boxZoneImgView small-max">
                        <a
                          className="closeIcon"
                          onClick={(e) => {
                            e.stopPropagation();
                            props.deleteImage(props.subType);
                            setLargeFiles([]);
                            setLogoLargeMAX("");
                            setImgDetail({ type: "", height: 0, width: 0 });
                            setImageError("");
                          }}
                        >
                          <i className="icon-close"></i>
                        </a>
                        <img src={sLogoLargeMAX} style={img} alt="thumb" />
                      </div>
                    )}

                  {thumbsLargeFiles.length > 0 &&
                    thumbsLargeFiles}
                </div>
              </div>
            </section>
            <div
              className={hasError ? "errorMsgupload show" : "errorMsgupload"}
            >
              {props.errors[name]?.message
                ? props.errors[name]?.message
                : "error"}
            </div>
            <div
              className={
                imageError !== "" ? "errorMsgupload show" : "errorMsgupload"
              }
            >
              {imageError}
            </div>
          </div>
        </>
      )}
      {props.type === "sponserSquareDropzone" && props.subType === 'large-x' && (
        <>
          <div className="tabletUploadArea">
            <section
              className="containerDropzone"
              style={{ width: props.secWidth }}
            >
              <div className="allBoxView">
                <div {...getRootProps({ className: "touchBox" })}>
                  <input {...getInputProps()} />
                  {thumbsLargeFiles.length === 0 &&
                    sLogoLargeX === "" && (
                      <div className="uploadBox" style={{ height: props.greySecMinHeight }}>
                        <i className="icon-imageUpload" />
                        <a className="blueTxt">Add image</a>
                      </div>
                    )}
                  {sLogoLargeX !== "" &&
                    thumbsLargeFiles.length === 0 && (
                      <div className="boxZoneImgView">
                        <a
                          className="closeIcon"
                          onClick={(e) => {
                            e.stopPropagation();
                            props.deleteImage(props.subType);
                            setLargeFiles([]);
                            setLogoLargeX("");
                            setImgDetail({ type: "", height: 0, width: 0 });
                            setImageError("");
                          }}
                        >
                          <i className="icon-close"></i>
                        </a>
                        <img src={sLogoLargeX} style={img} alt="thumb" />
                      </div>
                    )}

                  {thumbsLargeFiles.length > 0 &&
                    thumbsLargeFiles}
                </div>
              </div>
            </section>
            <div
              className={hasError ? "errorMsgupload show" : "errorMsgupload"}
            >
              {props.errors[name]?.message
                ? props.errors[name]?.message
                : "error"}
            </div>
            <div
              className={
                imageError !== "" ? "errorMsgupload show" : "errorMsgupload"
              }
            >
              {imageError}
            </div>
          </div>
        </>
      )}

      {props.type === "sponserBannerDropzone" && (
        <>
          <div className="bannerLongView">
            <div className="fullWidthUploadArea">
              <section
                className="containerDropzone"
                style={{ height: props.secHeight }}
              >
                <div {...getRootProps({ className: "bannerSecDropzone" })}>
                  <input {...getInputProps()} />
                  {sLogo === "" && thumbs.length === 0 && (
                    <div className="uploadSec">
                      <i className="icon-imageUpload" />
                      <a className="blueTxt">
                        Drag and drop an image or <a className="blue">browse</a>
                      </a>
                    </div>
                  )}
                  {sLogo !== "" && thumbs.length === 0 && (
                    <div className="bannerOuter">
                      <div className="bannerImgRow">
                        <div className="iconRealImg">
                          <img
                            src={sLogo}
                            className="bannerImg"
                            alt="Preview"
                          />
                        </div>
                        {/* <a
                          className="closeIcon"
                          onClick={(e) => {
                            e.stopPropagation();
                            props.deleteImage(props.subType);
                            setFiles([]);
                            setLogo("");
                            setImgDetail({ type: "", height: 0, width: 0 });
                            setImageError("");
                          }}
                        >
                          <i className="icon-close"></i>
                        </a> */}
                      </div>
                    </div>
                  )}
                  {thumbs.length > 0 && (
                    <div className="bannerOuter">{thumbs}</div>
                  )}
                </div>
              </section>
              <div
                className={hasError ? "errorMsgupload show" : "errorMsgupload"}
              >
                {props.errors[name]?.message
                  ? props.errors[name]?.message
                  : "error"}
              </div>
              <div
                className={
                  imageError !== "" ? "errorMsgupload show" : "errorMsgupload"
                }
              >
                {imageError}
              </div>
            </div>
            <div className="tabletUploadArea">
              <section className="containerDropzone">
                <div className="allBoxView">
                  <div {...getRootProps({ className: "touchBox" })}>
                    <input {...getInputProps()} />
                    {thumbsMobileView}
                    <div className="uploadBox">
                      <i className="icon-imageUpload" />
                      <a className="blueTxt">Add image</a>
                    </div>
                  </div>
                </div>
              </section>
              <div
                className={hasError ? "errorMsgupload show" : "errorMsgupload"}
              >
                {props.errors[name]?.message
                  ? props.errors[name]?.message
                  : "error"}
              </div>
            </div>
          </div>

          {/* {sLogo && sLogo !== "" && thumbs.length === 0 && (
            <div className="bannerScroll">
              <div className="bannerOuter">
                <div className="bannerImgRow">
                  <div className="iconRealImg">
                    <img src={sLogo} className="bannerImg" alt="thumb" />
                  </div>
                  <div className="imgInfo infoTxt">
                    <div className="topSec">
                      <span className="blackTxt">{GetFilename(sLogo)}</span>
                      <span className="greyTxt">
                        {bytesToSize(getImageSizeInBytesFromUrl(sLogo))}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )} */}
        </>
      )}
    </>
  );
}
CustomDropzone.defaultProps = {
  maxFileSize: 5 * 1000000,
  maxFiles: 1,
  multiple: false,
  sProfilePic: "",
};

export default CustomDropzone;
