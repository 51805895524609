import React from 'react';
import Pagination from "react-js-pagination";
import './Pagination.scss';

const CustomPagination = (props) => {
  const { handlePageChange, itemsCountPerPage, totalItemsCount, pageRangeDisplayed, activePage } = props
  var start = (activePage * itemsCountPerPage) - (itemsCountPerPage - 1);
  var end = Math.min(start + itemsCountPerPage - 1, totalItemsCount);
  return (
    <div className="componentPagination">
      <Pagination
        // hideNavigation
        prevPageText={<i className='icon-left-arrow iconArrow' />}
        nextPageText={<i className='icon-right-arrow iconArrow rightArrow' />}
        firstPageText={<div className="leftDiv"><i className='icon-left-arrow ' /><i className='icon-left-arrow' /></div>}
        lastPageText={<div className="leftDiv"><i className='icon-right-arrow ' /><i className='icon-right-arrow' /></div>}
        activePage={activePage}
        itemsCountPerPage={itemsCountPerPage}
        totalItemsCount={totalItemsCount}
        pageRangeDisplayed={pageRangeDisplayed}
        onChange={handlePageChange}
      />
      <span className="goto">Showing <span>{start}</span> to <span>{end}</span> of <span>{totalItemsCount}</span> entries </span>
    </div>
  );
}
CustomPagination.defaultProps = {
  itemsCountPerPage: 1, totalItemsCount: 1, pageRangeDisplayed: 7, activePage: 1
}

export default CustomPagination;
