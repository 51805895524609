import React from 'react';
import { Carousel } from 'react-bootstrap';
import './ImageSlider.scss';
import backLogin from "../../assets/images/back_login.svg"
import backForgot from "../../assets/images/back_forgot.svg"
import backReset from "../../assets/images/back_reset.svg"

class ImageSlider extends React.Component {
  constructor(props) {
    super(props);
    this.handleSelect = this.handleSelect.bind(this);
    this.state = {
      showFilter: false,
      index: 0
    };
  }

  handleSelect(selectedIndex, e) {
    this.setState({
      index: selectedIndex,
    });
  }

  render() {
    const { index } = this.state;

    return (
      <div className="imageView">
        <Carousel activeIndex={index}
          onSelect={this.handleSelect}
          touch={false}
          prevIcon={<a className="whiteBtn">
            <i className="icon-left-arrow" />
          </a>}
          nextIcon={<a className="yellowBtn">
            <i className="icon-left-arrow right" />
          </a>}
        >
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={backLogin}
              alt="First slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={backForgot}
              alt="second slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={backReset}
              alt="third slide"
            />
          </Carousel.Item>
        </Carousel>
        <div className="rightNoswitchSec">
          <div className="noSec"><span className="bigTxt">{index + 1}</span><span className="smalltxt">/ 03</span><div className="border" /></div>
        </div>
      </div>

    )
  }
}

export default (ImageSlider);
