import React from "react";
import './NotFound.scss';
// import txtLogo from '../../assets/images/damnTxt.png'
// import logo from '../../assets/images/logo_yellow.png'
import nofoundImg from '../../assets/images/nofound.png'
const logo = "https://res.cloudinary.com/dtzkljk1x/image/upload/v1611649515/Community-Club/Logo_Black_kpqcvk.png"


const NotFound = (props) => {
    return (
        <div className='nodataFound'>
            <div className="backYellowarea">
            </div>
            <div className="upperLogo">
                <div className="mainImg">
                    <img src={logo} alt="txtlogo" fluid="true" />
                </div>
            </div>

            <div className="centerPart">
                <p className='boldText'>Oops !!</p>
                <p className='simpleText'>The page your are looking for is not found</p>
                <div className="imgSec">
                    <img src={nofoundImg} alt="smallJogging" fluid="true" />
                </div>
            </div>


        </div>
    )
}



export default NotFound;