import React from "react";
import "./resetpassword.scss";
// import logo from "../../assets/images/logo_yellow.png";
import TextField from "../../components/TextField/TextField";
import CustomButton from "../../components/CustomButton/button";
import ToastComponent from "../../components/ToastComponent/ToastComponent";
import HookForm from "../../components/HookForm/HookForm";
import Constant from "../../util/constant";
import Message from "../../util/message";
import { Scrollbars } from "react-custom-scrollbars";
import WebService from "../../util/webService";
import ImageSlider from "../../components/ImageSlider/ImageSlider";
import pageLoader from "../../assets/images/pageLoader.svg";
const logo =
  "https://res.cloudinary.com/dtzkljk1x/image/upload/v1611649515/Community-Club/Logo_Black_kpqcvk.png";
const multiErrorFields = [
  { length: "Minimum 6 characters required" },
  { hasSpecialChar: "Minimum 1 special character required" },
  {
    hasNumbers: "Minimum 1 number required",
  },
];

const resetForm = {
  password: {
    name: "password",
    validate: {
      required: {
        value: true,
        message: Message.ErrorMessage.NewPassword,
      },
      pattern: {
        value: Constant.REGEX.OnlySpaces,
        message: Message.ErrorMessage.NewPassword,
      },
      validate: {
        length: (value) =>
          (value && value.length >= 6) || "Minimum 6 characters required",
        hasSpecialChar: (value) =>
          (value && value.match(Constant.REGEX.SPECIAL_CHAR)) ||
          "Minimum 1 special character required",
        hasNumbers: (value) =>
          (value && value.match(Constant.REGEX.NUMBER)) ||
          "Minimum 1 number required",
      },
    },
  },
  confirmpassword: {
    name: "confirmpassword",
    validate: {
      required: {
        value: true,
        message: Message.ErrorMessage.ConfirmPassword,
      },
      pattern: {
        value: Constant.REGEX.OnlySpaces,
        message: Message.ErrorMessage.ConfirmPassword,
      },
    },
  },
};

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showHidePassword: false,
      showPass: false,
      showConPass: false,
      encodedEmail: "",
      sClubIdentifier:"",
      toast: {
        show: false,
        type: "",
        msg: "",
        errorToast: false,
      },
      width: window.innerWidth,
      height: window.innerHeight,
      isLoading: false,
      _userId: "",
      role: "",
      isChecked: false,
    };
  }
  componentDidMount() {
    // const { encodedEmail } = this.props.match.params;
    let encodedString = this.props.location.pathname.replace(
      "/resetpassword/",
      ""
    );
    console.log(encodedString.split("/"))
    let paramArr = encodedString.split("/");
    let encodedEmail = paramArr[0];
    let sClubIdentifier = ""; 
    if(paramArr.length > 1){
      sClubIdentifier = paramArr[1]; 
    } 
    
    this.setState(
      {
        encodedEmail: encodedEmail,
        sClubIdentifier:sClubIdentifier
      },
      () => {
        this.onVerificationCheck();
      }
    );
    // console.log("=== encodedEmail ====", encodedEmail);
  }
  onVerificationCheck = async () => {
    this.setState({ isLoading: true });
    let param = {
      resetToken: this.state.encodedEmail,
    };
    await WebService.putWebServices(
      Constant.API.VERIFY_ACCOUNT,
      param,
      (response) => {
        console.log("this.state.isChecked",this.state.isChecked);
        if (!this.state.isChecked) {

          //I've used this isChecked condition because the API was getting called twice  
          this.setState({ isChecked: true });
          if (response.status >= 200 || response.status <= 210) {
            console.log("response1",response.data);
            console.log("response1",response.data.status === true);
            var role = response.data.data?.role;
            if (response.data.status === true) {
              console.log("response2",response.data);
              setTimeout(() => {
                this.setState({
                  _userId: response.data.data._UserId,
                  role: role,
                  isVerified: true,
                  isLoading: false,
                });
              }, 1500);
            } else {
              console.log("response3");
              console.log(response.status, "STATUSSSS123");
              this.setState({
                role: role,
                isVerified: false,
                isLoading: false,
                toast: {
                  show: true,
                  msg: response.data.msg,
                  errorToast: false,
                }
               
              });
              if (role === "UR001") {
                setTimeout(() => {
                  this.props.history.push("/");
                }, 5000);
              }
            }
          } else {
            console.log("response",response.data);
            this.setState({
              role: role,
              isVerified: false,
              isLoading: false,
              toast: {
                show: true,
                msg: response.data.msg,
                errorToast: false,
              },
            });
          }
        } else {
          
          this.setState({
            role: role,
            isVerified: false,
            isLoading: false,
            
          });
        }
      }
    );
  };
  handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };
  onFormSubmit = async (values) => {
    console.log(values, "VALUES");
    this.setState({ isLoading: true });
    const param = {
      _userId: this.state._userId,
      sPassword: values.password,
      sClubIdentifier: this.state.sClubIdentifier
    };
    await WebService.postWebServices(
      Constant.API.USER_RESET_PASSWORD,
      param,
      (response) => {
        if (response.status >= 200 || response.status <= 210) {
          if (response.data.status === true) {
            this.setState({
              isLoading: false,
              role: response.data.role,
              toast: {
                show: true,
                type: "Your password has been reset successfully.",
                msg: response.data.msg,
                errorToast: false,
              },
            });
            setTimeout(() => {
              this.setState({
                toast: {
                  show: false,
                  msg: "",
                  errorToast: false,
                },
              });
              this.props.history.push("/dashboard");
            }, 3000);
          } else {
            setTimeout(() => {
              this.setState({
                toast: {
                  show: false,
                  msg: "",
                  errorToast: false,
                },
              });
              // this.props.history.push('/dashboard')
            }, 5000);
          }
        }
      }
    );
  };

  onConfirmPassword = (e, formMethod) => {
    if (formMethod.watch("password") !== e.target.value) {
      return formMethod.setError(resetForm.confirmpassword.name, {
        type: "notMatch",
        message:
          e.target.value.length === 0
            ? Message.ErrorMessage.ConfirmPassword
            : Message.ErrorMessage.ConfirmPasswordInvalid,
      });
    }
  };
  render() {
    const { toast } = this.state;
    return (
      <div fluid className="resetPage">
        <ToastComponent
          onClose={() => {
            this.setState({
              toast: {
                show: false,
                type: this.state.toast.type,
                msg: this.state.toast.msg,
                errorToast: this.state.toast.errorToast,
              },
            });
          }}
          show={toast.show}
          toastMessage={toast.msg}
          toastMessageTye={toast.type}
          errorToast={toast.errorToast}
        />
        {this.state.isLoading && (
          <div className="wholePageLoader">
            <img src={pageLoader} alt="Loader" />
          </div>
        )}
        <div className="innerPage">
          <div className="resetleft">
            <Scrollbars>
              <div className="formSection">
                <div className="mainForm">
                  <HookForm onSubmit={(values) => this.onFormSubmit(values)}>
                    {(formMethod) => {
                      return (
                        <div>
                          <div className="logo">
                            <div className="logoMain">
                              <img src={logo} alt="Logo" />
                            </div>
                            {/* <p className="logoTxt">
                              COMMUNITY CLUB INITIATIVES
                            </p> */}
                          </div>
                          {this.state.isVerified && !this.state.isLoading && (
                            <>
                              <div className="bigTxtsec">
                                <span>Reset Password</span>
                                <p className="titleText">
                                  Choose a strong password, always...
                                </p>
                              </div>
                              <TextField
                                redStar={true}
                                labelName="NEW PASSWORD"
                                formMethod={formMethod}
                                multiErrorFields={multiErrorFields}
                                register={formMethod?.register(
                                  resetForm.password.validate
                                )}
                                name={resetForm.password.name}
                                errors={formMethod?.errors}
                                type={this.state.showPass ? "text" : "password"}
                                showHidePassword={() => this.showHidePassword()}
                                placeholder="Password"
                                iconClass={
                                  !this.state.showPass
                                    ? "icon-eye_notvisible"
                                    : "icon-eye_visible"
                                }
                                // onKeyDown={this.onKeyDown.bind(this)}
                                onKeyDown={this.handleKeyDown}
                                onIconClick={() =>
                                  this.setState({
                                    showPass: !this.state.showPass,
                                  })
                                }
                              />
                              <TextField
                                redStar={true}
                                labelName="CONFIRM PASSWORD"
                                formMethod={formMethod}
                                register={formMethod?.register(
                                  resetForm.confirmpassword.validate
                                )}
                                name={resetForm.confirmpassword.name}
                                errors={formMethod?.errors}
                                type={
                                  this.state.showConPass ? "text" : "password"
                                }
                                showHidePassword={() => this.showHidePassword()}
                                placeholder="Password"
                                iconClass={
                                  !this.state.showConPass
                                    ? "icon-eye_notvisible"
                                    : "icon-eye_visible"
                                }
                                // onKeyDown={this.onKeyDown.bind(this)}
                                onKeyDown={this.handleKeyDown}
                                onChange={(e) =>
                                  this.onConfirmPassword(e, formMethod)
                                }
                                onIconClick={() =>
                                  this.setState({
                                    showConPass: !this.state.showConPass,
                                  })
                                }
                              />
                              {this.state.role === "UR001" && (
                                <div className="checkbox-Forgot">
                                  <div className="forgotLink">
                                    <a href="/login">Back to Login</a>
                                  </div>
                                </div>
                              )}
                              <CustomButton
                                type="submit"
                                title="RESET MY PASSWORD"
                                disabled={!formMethod?.formState.isValid}
                              />
                            </>
                          )}
                          {!this.state.isVerified && !this.state.isLoading && (
                            <div className="error">{this.state.toast.msg}</div>
                          )}
                        </div>
                      );
                    }}
                  </HookForm>
                </div>
              </div>
            </Scrollbars>
          </div>
          <div className="resetright">
            <div className="imageSection">
              <ImageSlider />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ResetPassword;
