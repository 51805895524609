import React from 'react';
import './Footer.scss';

export default class Footer extends React.Component {
  render() {
    return (
      <div className={"footerMain " + this.props.divClassname}>
        <div className="footerArea">
          <span className="footertxt">Copyright © 2020 Community Club Initiatives</span>
        </div>

      </div>
    );
  }
}