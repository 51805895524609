const Message = {
    ErrorMessage: {
        EmailEmpty: 'Email cannot be empty',
        NameEmpty: 'Full Name cannot be empty',
        TitleEmpty: 'Title cannot be empty',
        FacebookEmpty: 'Facebook URL cannot be empty',
        TwitterEmpty: 'Twitter URL cannot be empty',
        SponsersNameEmpty: 'Sponsers Name cannot be empty',
        MobNumberEmpty: 'Mobile Number cannot be empty',
        PhoneNumberEmpty: 'Phone Number cannot be empty',
        PhoneNumberLength: 'Phone number must be between 10 to 11 digit.',
        EmailInvalid: 'Are you sure you entered the valid email?',
        PasswordEmpty: 'Password cannot be empty',
        OTPEmpty: 'OTP cannot be empty',
        PasswordInvalid: 'Are you sure you entered the valid email?',
        ConfirmPassword: 'Confirm Password cannot be empty',
        NewPassword: 'New Password cannot be empty',
        ConfirmPasswordInvalid: 'Confirm Password should be same',
    }
}

export default Message