import React from 'react';
import './viewprofile.scss';
import league2 from '../../assets/images/league5.png'
import { Scrollbars } from 'react-custom-scrollbars';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import CustomMenuBar from '../../components/CustomMenuBar/CustomMenuBar';
import CustomButton from '../../components/CustomButton/button';
import ToastComponent from '../../components/ToastComponent/ToastComponent';
import CustomModal from '../../components/Modal/Modal';
import MainContext from '../../MainContext';
import Constant from "../../util/constant";
import WebService from "../../util/webService";
import getUser from '../../util/helper';
// import logoYellow from "../../assets/images/logo_yellow.png";
const logoYellow = "https://res.cloudinary.com/dtzkljk1x/image/upload/v1611649515/Community-Club/Logo_Black_kpqcvk.png";

class ViewProfile extends React.Component {
  static contextType = MainContext
  constructor(props) {
    super(props);
    this.state = {
      toast: {
        show: false,
        type: "Sucess",
        msg: "The club has been edited successfully.",
        errorToast: false
      },
      togglepageVisible: true,
      ContactUserModalVisible: false
    };
  }
  onApply(toggleVisible) {
    this.setState({ togglepageVisible: toggleVisible })
  }
  onSubmitClick = async (values) => {
    const { updateButtonLoading } = this.context;
    this.setState({ defaultForm: values });
    updateButtonLoading(true);
    var formData = new FormData();
    formData.append("userId", this.state.oUserId);

    if (values.sProfilePic !== "") {
      formData.append("sProfilePic", values.sProfilePic);
    }

    // formData.append("sClubIdentifier", config.);
    formData.append("sFullName", values.sFullName);
    formData.append("sEmailId", values.sEmailId);
    formData.append("sCountryCode", values.sCountryCode);
    formData.append("nPhoneNumber", values.sPhoneNumber);


    // formData.append("sClubLogo", this.state.sClubLogo);
    await WebService.postWebServices(Constant.API.USER_EDIT, formData, (response) => {
      if (response.status || response.status >= 200 || response.status <= 210) {
        if (response.data.status === true) {
          this.setState({
            ContactUserModalVisible: false,
            oUserId: "",
            defaultForm: {},
            toast: {
              show: true,
              type: "Success",
              msg: response.data.msg,
              errorToast: false,
            },
          });
          this.getUserProfile();
        } else {
          this.setState({
            errorField: response.data.errorField,
            userExistsError: response.data.msg,
          });
        }
      }
    });
    updateButtonLoading(false);
    setTimeout(() => {
      this.setState({
        toast: {
          show: false,
          type: this.state.toast.type,
          msg: this.state.toast.msg,
          errorToast: this.state.toast.errorToast,
        },
      });
    }, 5000);
  };
  componentWillMount() {
    this.getUserProfile();
  }
  getUserProfile = async () => {
    const { updateLoading } = this.context;
    updateLoading(true);
    let userDetails = getUser()
    console.log(userDetails._id, "======>");
    let param = {
      userId: userDetails._id,
    };
    await WebService.postWebServices(
      Constant.API.USER_DETAIL,
      param,
      (response) => {
        if (response.status >= 200 || response.status <= 210) {
          if (response.data.status === true) {
            console.log(response);
            this.setState({
              // toast: {
              //   show: true,
              //   type: "Success",
              //   msg: response.data.msg,
              //   errorToast: false,
              // },
              oUserId: response.data.data._id,
              name: response.data.data.sFullName,
              email: response.data.data.sEmailId,
              number: response.data.data.nPhoneNumber,
              countryCode: response.data.data.sCountryCode ? response.data.data.sCountryCode : "44" ,
              sProfilePic: response.data.data.sProfilePic
            });
            //If last record of the current page has been deleted, then set the page number to previous page.

          }
        } else {
          this.setState({
            toast: {
              show: true,
              type: "Failed",
              errorToast: true,
            },
          });
        }
      }
    );
    updateLoading(false);
    setTimeout(() => {
      this.setState({
        toast: {
          show: false,
          type: this.state.toast.type,
          msg: this.state.toast.msg,
          errorToast: this.state.toast.errorToast,
        },
      });
    }, 5000);
  };
  render() {
    const { toast, ContactUserModalVisible, name, email, number, countryCode, sProfilePic, oUserId } = this.state
    return (
      <div className="ViewProfilePage">
        <Header {...this.props} logonotVisible={true} />
        <CustomMenuBar onToggleClick={(toggleVisible) => this.onApply(toggleVisible)} {...this.props} />
        <div className="centerSec">
          <ToastComponent onClose={() => {
            this.setState({
              toast: {
                show: false,
                type: this.state.toast.type,
                msg: this.state.toast.msg,
                errorToast: this.state.toast.errorToast
              }
            })
          }} show={toast.show} toastMessage={toast.msg} toastMessageTye={toast.type} errorToast={toast.errorToast} />
          <div className={this.context.isDrawerOpen ? "rightMain" : "rightMain sidePadding"} >
            <div className="searchSec">
              <span className="blackdarkTxt">Profile Details</span>
            </div>
            <Scrollbars className="ViewProfile">
              <div className="whiteBox">
                <div className="leftSec">
                  <div className="imgSec">
                    <img src={sProfilePic && sProfilePic!=="" ? sProfilePic.replace("upload/","upload/w_242,c_scale/") : logoYellow} alt="Profile" />
                  </div>
                  <span className="blueDarkTxt">{name}</span>
                </div>
                <div className="rightSec">
                  <div className="textSec">
                    <div className="infoView">
                      <span className="labelTxt">EMAIL ADDRESS</span>
                      <span className="infoTxt"><i className="icon-filled_mail" />{email}</span>
                    </div>
                    <div className="infoView">
                      <span className="labelTxt">PHONE NUMBER</span>
                      <span className="infoTxt"><i className="icon-Mobile" />{"+" + countryCode + " " + number}</span>
                    </div>
                  </div>
                  <div className="btnSec">
                    <CustomButton type="submit" onClick={() => {

                      this.setState({
                        ContactUserModalVisible: !ContactUserModalVisible,
                        defaultForm: {
                          sFullName: name,
                          sEmailId: email,
                          sPhoneNumber: number,
                          sCountryCode: countryCode,

                          sProfilePic: sProfilePic,
                        },
                      })
                    }}
                      title="EDIT" />
                  </div>

                </div>

              </div>
            </Scrollbars>
          </div>
          <CustomModal
            modalType="contactModal"
            defaultForm={this.state.defaultForm}
            showModal={ContactUserModalVisible}
            onHide={() => this.setState({ ContactUserModalVisible: !ContactUserModalVisible })}
            titleName="Profile Details"
            oUserId={oUserId}
            isAdmin={true}
            sProfilePic={this.state.defaultForm?.sProfilePic}
            onSubmit={(details) => {

              this.onSubmitClick(details);
            }}
          />
        </div>
        <Footer divClassname={this.context.isDrawerOpen ? "simpleFooter" : "footersidePadding"} />
      </div >
    )
  }
}

export default (ViewProfile);

