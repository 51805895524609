import React from 'react';
import { Toast } from 'react-bootstrap';
import './ToastComponent.scss';


const ToastComponent = (props) => {
  return (
    <Toast show={props.show} className={props.errorToast ? "error-txt" : "success-txt"} delay={5000} autohide >
      <div className="iconView">
        <span className={props.errorToast ? "icon-close_round iconTickSmall" : "icon-valid-message iconTick"}></span>
      </div>
      <div className="contentView">
        {props.toastMessageType && <p className="boldTxt">{props.toastMessageType}</p>}
        <p className="simpleTxt">{props.toastMessage}</p>
      </div>
      <div className="closeView">
        <a onClick={props.onClose}><span className="icon-close"></span></a>
      </div>

    </Toast>
  )
}

export default (ToastComponent);
