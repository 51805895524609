import React from "react";

const MainContext = React.createContext({
  isDrawerOpen: false,
  isLoading: false,
  isButtonLoading: false,
  contextToast: {
    show: false,
    type: "Success",
    msg: "",
    errorToast: false,
  },
});

export default MainContext;
