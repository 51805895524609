import React from 'react';
import { Button } from 'react-bootstrap';
import './CustomDropdown.scss';
import PropTypes from 'prop-types';
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'

class CustomDropdown extends React.Component {
    constructor(props, context) {
        super(props, context);
    }
    render() {
        let { selectedValue, dropDownItems, placeholder, onSelect, labelName, redStar } = this.props
        return (
            <div className="mb-2 customDropdown">
                {labelName && <p className="labelTxt">{labelName}</p>}
                {redStar && <span className="redStar">*</span>}
                {['Primary'].map(
                    (evt) => (
                        <DropdownButton
                            key={evt}
                            id={`dropdown-variants-${evt}`}
                            variant={evt.toLowerCase()}
                            title={selectedValue || placeholder}
                            onSelect={(evt) => onSelect(evt)}
                            className={selectedValue ? "selected" : ""}
                        >
                            <div className="dropdownData">
                                {
                                    dropDownItems.map((item) => {
                                        return <Dropdown.Item eventKey={item}>{item}</Dropdown.Item>
                                    })
                                }
                            </div>
                        </DropdownButton>
                    )
                )
                }
            </div>


        )
    }
}

CustomDropdown.propTypes = {
    onClick: PropTypes.func
};

export default CustomDropdown;

