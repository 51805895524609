import React, { useState, useContext } from "react";
import "./Contactmodal.scss";
import TextField from "../TextField/TextField";
import Constant from "../../util/constant";
import Message from "../../util/message";
import HookForm from "../HookForm/HookForm";
import CustomButton from "../../components/CustomButton/button";
import CustomDropzone from "../../components/CustomDropzone/CustomDropzone";
import { Controller } from "react-hook-form";
import MainContext from "../../MainContext";
import Spinner from "react-bootstrap/Spinner";

const contactForm = {
  name: {
    name: "sFullName",
    validate: {
      required: {
        value: true,
        message: Message.ErrorMessage.NameEmpty,
      },
      pattern: {
        value: Constant.REGEX.OnlySpaces,
        message: Message.ErrorMessage.NameEmpty,
      },
    },
  },
  email: {
    name: "sEmailId",
    validate: {
      required: {
        value: true,
        message: Message.ErrorMessage.EmailEmpty,
      },
      pattern: {
        value: Constant.REGEX.email,
        message: Message.ErrorMessage.EmailInvalid,
      },
    },
  },
  sProfileImage: {
    name: "sProfilePic",
    // validate: {
    //   required: {
    //     value: true,
    //     message: "Please select profile image",
    //   },
    // },
  },
  sPhoneNumber: {
    name: "sPhoneNumber",
    validate: {
      required: {
        value: false,
        message: Message.ErrorMessage.PhoneNumberEmpty,
      },
      minLength: {
        value: 11,
        message: Message.ErrorMessage.PhoneNumberLength,
      },
      maxLength: {
        value: 11,
        message: Message.ErrorMessage.PhoneNumberLength,
      },
    },
  },
  sPosition: {
    name: "sPosition",
    validate: {

    },
  },
};

const validateMobile = (formMethod, value = "", mask = "          ") => {
  !value || value === mask
    ? formMethod.setError(contactForm.sPhoneNumber.name, {
      type: "manual",
      message: Message.ErrorMessage.MobNumberEmpty,
    })
    : formMethod.clearErrors(["sPhoneNumber"]);
  formMethod.setValue(contactForm.sPhoneNumber.name, value);
};

const Contactmodal = (props) => {
  let {
    closeClick,
    defaultForm,
    onSubmit,
    userExistsError,
    errorField,
  } = props;

  const [selectColor, setSelectColor] = useState("yellow");
  const { isButtonLoading } = useContext(MainContext);
  const df = defaultForm;
  console.log("isAdmin===>", props
    .isAdmin);

  return (
    <div className="ContactmodalMain">
      <HookForm
        defaultForm={df}
        onSubmit={(formMethod) => {
          onSubmit(formMethod);
        }}
      >
        {(formMethod) => {
          console.log("values=====>", formMethod.getValues());
          return (
            <>
              <div className="uploadSec">
                <Controller
                  name={contactForm.sProfileImage.name}
                  control={formMethod?.control}
                  rules={contactForm.sProfileImage.validate}
                  errors={formMethod?.errors}
                  defaultValue={""}
                  as={
                    <CustomDropzone
                      {...props}
                      type="profileImgDropzone"
                      uploadTitle="Upload user's photo"
                      circleIcon="icon-no-display"
                    />
                  }
                />
              </div>

              <div className="formSec">
                <TextField
                  labelName="FULL NAME"
                  redStar={true}
                  formMethod={formMethod}
                  register={formMethod?.register(contactForm.name.validate)}
                  name={contactForm.name.name}
                  errors={formMethod?.errors}
                  autoFocus={true}
                  type="text"
                  placeholder="Enter Full Name"
                />
                <TextField
                  customError={
                    errorField !== "" && errorField === "email"
                      ? {
                        name: contactForm.email.name,
                        message: userExistsError,
                      }
                      : ""
                  }
                  labelName="EMAIL ADDRESS"
                  redStar={false}
                  formMethod={formMethod}
                  register={formMethod?.register(contactForm.email.validate)}
                  name={contactForm.email.name}
                  errors={formMethod?.errors}
                  autoFocus={true}
                  type="text"
                  placeholder="Enter Email Address"
                />
                <TextField
                  labelName="PHONE NUMBER"
                  redStar={false}
                  // onChange={(e) => onOTPChange(formMethod, e, '          ')}
                  maskInput={true}
                  maskType="99999999999"
                  formMethod={formMethod}
                  register={formMethod?.register(
                    contactForm.sPhoneNumber.validate
                  )}
                  rules={contactForm.sPhoneNumber.validate}
                  name={contactForm.sPhoneNumber.name}
                  errors={formMethod?.errors}
                  placeholder="Enter Phone Number"
                  countryCodeValue={df.sCountryCode}
                  customError={
                    errorField !== "" && errorField === "phone"
                      ? {
                        name: contactForm.sdPhoneNumber.name,
                        message: userExistsError,
                      }
                      : ""
                  }
                />
                {props.isAdmin ? "" :
                  <TextField
                    labelName="POSITION"
                    redStar={false}
                    formMethod={formMethod}
                    register={formMethod?.register(contactForm.sPosition.validate)}
                    name={contactForm.sPosition.name}
                    errors={formMethod?.errors}
                    autoFocus={true}
                    type="text"
                    placeholder="Enter Position"
                  />}

                <div className="btnGroup">
                  <CustomButton
                    type="submit"
                    title={
                      !isButtonLoading
                        ? props.oUserId !== ""
                          ? "Update"
                          : "Add"
                        : ""
                    }
                    disabled={!formMethod?.formState.isValid || isButtonLoading}
                  >
                    {isButtonLoading && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                  </CustomButton>
                  <CustomButton
                    className="cancelBtn"
                    onClick={closeClick}
                    title="Cancel"
                  />
                </div>
              </div>
            </>
          );
        }}
      </HookForm>
    </div>
  );
};

export default Contactmodal;
