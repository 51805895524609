import React, { useEffect,useState } from "react";
import "./ResetSuccessPage.scss";
// import logo from "../../assets/images/logo_yellow.png";
import playstoreImg from "../../assets/images/Google_Play_Store.png";
import applestoreImg from "../../assets/images/App_Store.png";
const logo = "https://res.cloudinary.com/dtzkljk1x/image/upload/v1611649515/Community-Club/Logo_Black_kpqcvk.png";
const ResetSuccessPage = (props) => {
  const [AndroidUrl, setAndroidUrl] = useState("")
  const [IosUrl, setIosUrl] = useState("")
  useEffect(() => {
    console.log(props.match.params.id);
    if(props.match.params.id === "grenta-fc"){
      setAndroidUrl("https://play.google.com/store/apps/details?id=com.cc.gretnafc")
      setIosUrl("https://apps.apple.com/us/app/gretna-fc-2008-official-app/id1547784224")
    }else if(props.match.params.id === "greystone-rovers"){
      setAndroidUrl("https://play.google.com/store/apps/details?id=com.cc.greystone")
      setIosUrl("https://apps.apple.com/us/app/greystone-rovers/id1574600729")
    }else if(props.match.params.id === "threave-roversfc"){
      setAndroidUrl("https://play.google.com/store/apps/details?id=com.cc.threaveroversfc")
      setIosUrl("https://apps.apple.com/us/app/threave-roversfc/id1582757107")
    }else if(props.match.params.id === "newtongrange-star"){
      setAndroidUrl("https://play.google.com/store/apps/details?id=com.cc.newtongrange")
      setIosUrl("https://apps.apple.com/us/app/newtongrange-star/id1586115189")
    }
    // if (!props.location.state?.resetSuccess) {
    //   // props.history.push('/');
    // }
  }, []);
  return (
    <div className="nodataFound">
      <div className="centerPart">
        <div className="txtLogo">
          <img src={logo} alt="txtlogo" fluid="true" />
          {/* <p className="logoTxt">COMMUNITY CLUB INITIATIVES</p> */}
        </div>
        <p className="boldText">Password changed successfully</p>
        <p className="simpleText">
          For login please download the application from below links.
        </p>
        <div className="applinkSec">
          <div className="appLine">
            <a href={AndroidUrl}>
              <img src={playstoreImg} alt="smallJogging" fluid="true" />
            </a>
            <a href={IosUrl}>
              <img src={applestoreImg} alt="smallJogging" fluid="true" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetSuccessPage;
