import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';

function HookForm(props) {
    let { onSubmit } = props

    const method = useForm({
        mode: "onChange",
        defaultValues: props.defaultForm,
        criteriaMode: "all"
    }); // initialise the hook

    useEffect(() => {
        props.defaultForm && Object.keys(props.defaultForm).forEach(key => {
            method.setValue(key, props.defaultForm[key])
        })
    }, [props.defaultForm])

    return (
        <form onSubmit={method.handleSubmit(onSubmit)}>
            {props.children(method, Controller)}
        </form>
    );
}

HookForm.defaultProps = {
    defaultForm: {},
    onSubmit: () => { console.log('onSubmit function prop is not passed..!!') }
}
export default HookForm;
