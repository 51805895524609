import React, { useContext, useState } from 'react';
import './Header.scss';
// import logo from '../../assets/images/logo_white.png';
import pageLoader from '../../assets/images/pageLoader.svg';
import MainContext from '../../MainContext';
import CustomModal from '../../components/Modal/Modal';
const logo = "https://res.cloudinary.com/dtzkljk1x/image/upload/w_150,c_scale/v1611649515/Community-Club/Logo_White_w4xjoa.png"

const Header = (props) => {
  const { isLoading } = useContext(MainContext);
  const [logoutModalVisible, setLogoutModalVisible] = useState(false);

  return (
    <div className="headerMain">
      <div className="topHeaderSec">
        <div className="logo">
          {!props.logonotVisible && <div className="logoVisble">
            <div className='logoMain'>
              <img src={logo} alt="Logo" />
            </div>
            {/* <p className="logoTxt">COMMUNITY CLUB INITIATIVES</p> */}
          </div>}
        </div>
        <div className="buttonSec">
          <a className="homeIcon" onClick={() => { props.history.push('/dashboard') }}>
            <i className="icon-dashboard"></i>
            <span className={props.match.path === '/Dashboard' ? "borderBottom" : " "} />
          </a>
          <a className="logoutBtn" href='/viewprofile' >profile</a>
          <a className="logoutBtn" onClick={() => setLogoutModalVisible(!logoutModalVisible)}
          >logout</a>
        </div>
      </div>
      {isLoading && <div className="wholePageLoader">
        <img src={pageLoader} alt="Loader"/>
      </div>}
      <CustomModal
        showModal={logoutModalVisible}
        onHide={() => setLogoutModalVisible(!logoutModalVisible)}
        onClick={() => {
          localStorage.clear();
          props.history.push("/");
        }}
        titleName="Logout"
        isStatusModal={true}
        statusTitle="Logout"
        statusText="Are you sure you want to Logout ?"
        modalType="deleteModal" />
    </div>
  );
}

export default Header;
