import React from "react";
import { Modal } from "react-bootstrap";
import "./Modal.scss";
import Clubmodal from "../Clubmodal/Clubmodal";
import Deletemodal from "../Deletemodal/Deletemodal";
import Usermodal from "../Usermodal/Usermodal";
import Sponsersmodal from "../Sponsersmodal/Sponsersmodal";
import SettingsModal from "../SettingsModal/SettingsModal";
import Contactmodal from "../Contactmodal/Contactmodal";

class CustomModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      title: "",
    };
  }
  hideModal = (modal) => {
    this.setState({ showModal: false });
  };
  render() {
    return (
      <Modal
        className={this.props.modalType}
        show={this.props.showModal}
        onHide={() => this.props.onHide()}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div
            className={
              this.props.modalType === "deleteModal"
                ? "headerView borderHeader"
                : "headerView"
            }
          >
            <span
              className={
                this.props.modalType === "deleteModal" ? "redTxt" : "blackTxt"
              }
            >
              {this.props.titleName}
            </span>
            <a className="closeBtn" onClick={() => this.props.onHide()}>
              <i className="close"></i>
            </a>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="bodyView">
            {this.props.modalType === "clubModal" && (
              <Clubmodal
                closeClick={() => this.props.onHide()}
                {...this.props}
              />
            )}
            {this.props.modalType === "deleteModal" && (
              <Deletemodal
                deletItemName={this.props.deletItemName}
                closeClick={() => this.props.onHide()}
                {...this.props}
              />
            )}
            {this.props.modalType === "userModal" && (
              <Usermodal
                closeClick={() => this.props.onHide()}
                {...this.props}
              />
            )}
            {this.props.modalType === "sponsersModal" && (
              <Sponsersmodal
                closeClick={() => this.props.onHide()}
                {...this.props}
              />
            )}
            {this.props.modalType === "settingsModal" && (
              <SettingsModal
                // selectedColor={this.props.selectedColor}
                closeClick={() => this.props.onHide()}
                {...this.props}
              />
            )}
            {this.props.modalType === "contactModal" && (
              <Contactmodal
                closeClick={() => this.props.onHide()}
                {...this.props}
              />
            )}
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default CustomModal;
