import React from 'react';
import './viewclub.scss';
import Constant from "../../util/constant";
import fixtureImg from '../../assets/images/fixturesPNG.png'
import sponserImg from '../../assets/images/sponsersPNG.png'
import contactImg from '../../assets/images/contactPNG.png'
import { Scrollbars } from 'react-custom-scrollbars';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import CustomMenuBar from '../../components/CustomMenuBar/CustomMenuBar';
import ToastComponent from '../../components/ToastComponent/ToastComponent';
import WebService from "../../util/webService";
import MainContext from '../../MainContext';
import moment from 'moment';


class ViewClub extends React.Component {
  static contextType = MainContext
  constructor(props) {
    super(props);
    
    this.state = {
      toast: {
        show: false,
        type: "Sucess",
        msg: "The club has been edited successfully.",
        errorToast: false
      },
      clubs: [],
      searchText: '',
      NoData: '',
      typingTimeout: 0,
      togglepageVisible: true,
      detail: {},
      totalUsers: 0
    };
  }
  onApply(toggleVisible) {
    this.setState({ togglepageVisible: toggleVisible })
  }
  getDetail = async () => {
    const id = this.props.location.state.iClubId;
    await WebService.getWebServices(
      Constant.API.CLUB_DETAIL,
      { oClubId: id },
      (response) => {
        if (response.status >= 200 || response.status <= 210) {
          let mainData = response.data.data;
          if (response.data.status === true) {
            this.setState({
              detail: mainData,
              totalUsers: response.data.totalUsers
            });
          } else {
            this.setState({
              detail: {},
              NoData: mainData.msg
            });
          }
        }
      });
  }
  componentWillMount() {
    if(!this.props.location.state){
      this.props.history.push('/clubs')
    }else{
      this.getDetail();
    }
  }
  render() {
    const { toast, totalUsers, detail } = this.state;
    return (
      <div className="ViewClubPage">
        <Header {...this.props} logonotVisible={true} />
        <CustomMenuBar onToggleClick={(toggleVisible) => this.onApply(toggleVisible)} {...this.props} />
        <div className="centerSec">
          <ToastComponent  onClose={() => {
          this.setState({
            toast: {
              show: false,
              type: this.state.toast.type,
              msg: this.state.toast.msg,
              errorToast: this.state.toast.errorToast
            }
          })
        }} show={toast.show} toastMessage={toast.msg} toastMessageTye={toast.type} errorToast={toast.errorToast} />
          <div className={this.context.isDrawerOpen ? "rightMain" : "rightMain sidePadding"} >
            <div className="searchSec">
              <a className="backICON" onClick={() => { this.props.history.push('clubs') }}><i className="icon-left-arrow" />
              <span className="blackdarkTxt" onClick={() => { this.props.history.push('clubs') }}>Clubs</span></a>
              {/* <span className="divider"></span> */}
              {/* <div className="searchMain">
                <SearchBox placeholder="Search..." onChange={this.onSearch} />
              </div> */}
            </div>
            <Scrollbars className="clublisting">
              <div className="pageinnerscroll">
                <div className="listingTopSec">
                  <div className="backImgSec">
                    <div className="imgCircle">
                      <div className="imgDiv">
                        <img src={detail.sClubLogo} className="clublogoMain" alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="blacTxtSec">
                    <span className="blacTxt">{detail.sClubName}</span>
                  </div>
                  <div className="greyTxtSec">
                    <span className="greyTxt">{totalUsers} <span className="greyTxt">{parseInt(totalUsers) === 1 ? 'User' : 'Users'}</span></span>
                  </div>
                  <div className="greyTxtSec">
                    <span className="greyTxt">Since {moment(detail.dCreatedAt).format('YYYY')}</span>
                  </div>
                </div>
                <div className="viewClub">
                  <ul>
                    <li>
                      <a className="viewCard" onClick={() => { this.props.history.push('/club/fixtures', { iClubId: this.props.location.state.iClubId, sClubName: detail.sClubName, sClubIdentifier: detail.sClubIdentifier, sClubLogo: detail.sClubLogo, since: moment(detail.dCreatedAt).format('YYYY'), totalUsers: totalUsers }) }} >
                        <div className="imgBig">
                          <img src={fixtureImg} alt="" />
                        </div>
                        <span className="yelloBigTxt">
                          Fixtures & Tables
                          League Associations
                          </span>
                        <div className="background"></div>
                      </a>

                    </li>
                    <li>
                      <a className="viewCard" onClick={() => { this.props.history.push('/club/sponsors', { iClubId: this.props.location.state.iClubId, sClubName: detail.sClubName, sClubIdentifier: detail.sClubIdentifier, sClubLogo: detail.sClubLogo, since: moment(detail.dCreatedAt).format('YYYY'), totalUsers: totalUsers }) }} >
                        <div className="imgBig">
                          <img src={sponserImg} alt="" />
                        </div>
                        <span className="yelloBigTxt">
                          List of Club Sponsors
                          </span>
                        <div className="background"></div>
                      </a>
                    </li>

                    <li>
                      <a className="viewCard" onClick={() => { this.props.history.push('/club/contacts', { iClubId: this.props.location.state.iClubId, sClubName: detail.sClubName, sClubIdentifier: detail.sClubIdentifier, sClubLogo: detail.sClubLogo, since: moment(detail.dCreatedAt).format('YYYY'), totalUsers: totalUsers }) }} >
                        <div className="imgBig">
                          <img src={contactImg} alt="" />
                        </div>
                        <span className="yelloBigTxt">
                          Contact Us Information
                          </span>
                        <div className="background"></div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </Scrollbars>
          </div>
        </div>
        <Footer divClassname={this.context.isDrawerOpen ? "simpleFooter" : "footersidePadding"} />
      </div >
    )
  }
}

export default (ViewClub);

