import React from 'react';
import './forgotpassword.scss';
// import logo from '../../assets/images/logo_yellow.png';
import TextField from '../../components/TextField/TextField'
import CustomButton from '../../components/CustomButton/button';
import ToastComponent from '../../components/ToastComponent/ToastComponent';
import HookForm from '../../components/HookForm/HookForm';
import Constant from "../../util/constant";
import Message from '../../util/message';
import WebService from "../../util/webService";

import { Scrollbars } from 'react-custom-scrollbars';
import ImageSlider from '../../components/ImageSlider/ImageSlider';
import pageLoader from '../../assets/images/pageLoader.svg';
//https://res.cloudinary.com/dtzkljk1x/image/upload/v1611649515/Community-Club/Logo_Black_kpqcvk.png
const logo = "https://res.cloudinary.com/dtzkljk1x/image/upload/v1611649515/Community-Club/Logo_Black_kpqcvk.png";
const forgotpassForm = {
  email: {
    name: 'email',
    validate: {
      required: {
        value: true,
        message: ((Message.ErrorMessage.EmailEmpty))
      }, pattern: {
        value: Constant.REGEX.email,
        message: ((Message.ErrorMessage.EmailInvalid))
      }
    },
  }
}

class ForgotPassword extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showHidePassword: false,
      showPass: false,
      toast: {
        show: false,
        type: "",
        msg: "",
        errorToast: false
      },
      width: window.innerWidth,
      height: window.innerHeight,
      isLoading: false
    };
  }

  handleKeyDown = e => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };
  onFormSubmit = async (values) => {
    this.setState({ isLoading: true })
    const param = {
      sEmailId: values.email,
      sDeviceType: 'web'
    };
    await WebService.postWebServices(
      Constant.API.USER_FORGOT_PASSWORD,
      param,
      (response) => {
        if (response.status >= 200 || response.status <= 210) {
          if (response.data.status === true) {
            this.setState({
              toast: {
                show: true,
                type: "Recovery link sent!",
                msg: response.data.msg,
                errorToast: false
              }
            })
          } else {
            this.setState({
              toast: {
                show: true,
                type: "Failed",
                msg: response.data.msg,
                errorToast: true
              }
            });
          }
        }
      });
    this.setState({ isLoading: false })
    setTimeout(() => {
      this.setState({
        toast: {
          show: false,
          type: this.state.toast.type,
          msg: this.state.toast.msg,
          errorToast: this.state.toast.errorToast
        }
      })
    }, 5000);
  }
  goToLogin = () => {
    this.props.history.push('/login');
  }
  render() {
    const { toast } = this.state
    return (
      <div className="forgotPage">
        <ToastComponent onClose={() => {
          this.setState({
            toast: {
              show: false,
              type: this.state.toast.type,
              msg: this.state.toast.msg,
              errorToast: this.state.toast.errorToast
            }
          })
        }} show={toast.show} toastMessage={toast.msg} toastMessageTye={toast.type} errorToast={toast.errorToast} />
        {this.state.isLoading && <div className="wholePageLoader">
          <img src={pageLoader} alt="" />
        </div>}
        <div className='innerPage'>
          <div className="forgotPassleft">
            <Scrollbars>
              <div className="formSection">
                <div className='mainForm'>
                  <HookForm
                    defaultForm={{ "email": '' }}
                    onSubmit={(values) => this.onFormSubmit(values)}>
                    {(formMethod) => {
                      return (
                        <div>
                          <div className='logo'>
                            <div className='logoMain'>
                              <img src={logo} alt="Logo" />
                            </div>
                            {/* <p className="logoTxt">COMMUNITY CLUB INITIATIVES</p> */}
                          </div>
                          <div className="bigTxtsec">
                            <span>Forgot Password</span>
                            <p className='titleText'>Don't worry, happens to the best of us</p>
                          </div>

                          <TextField
                            redStar={true}
                            labelName="EMAIL"
                            formMethod={formMethod}
                            register={formMethod?.register(forgotpassForm.email.validate)}
                            name={forgotpassForm.email.name}
                            errors={formMethod?.errors}
                            autoFocus={true}
                            type="text"
                            placeholder="johndoe@gmail.com"
                            onKeyDown={this.handleKeyDown}
                          />
                          <div className='checkbox-Forgot'>
                            <div className='forgotLink'>
                              <a onClick={this.goToLogin} >Back to Login</a>
                            </div>
                          </div>
                          <CustomButton type="submit"
                            title="EMAIL ME A RECOVERY LINK" disabled={!formMethod?.formState.isValid} />

                        </div>
                      )
                    }}
                  </HookForm>
                </div>
              </div>
            </Scrollbars>
          </div>
          <div className='forgotPassright'>
            <div className='imageSection'>
              <ImageSlider />
            </div>
          </div>

        </div>

      </div>
    )
  }
}

export default (ForgotPassword);
