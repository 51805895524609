import React from 'react';
import { Button } from 'react-bootstrap';
import './button.scss';

class CustomButton extends React.Component {
    
    render() {
        let { title, children, disabled, type, className, onKeyDown, onClick } = this.props
        return (

            <Button
                className={className}
                type={type} onKeyDown={onKeyDown} disabled={disabled} onClick={onClick}>

                {children}{title}


            </Button>

        )
    }
}

CustomButton.defaultProps = {
    className: 'submitBtn',
};


export default CustomButton;

