import React from 'react';
import './Deletemodal.scss';
import CustomButton from '../../components/CustomButton/button';


const Deletemodal = (props) => {
  let { deletItemName, closeClick } = props
  return (
    <div className="deletemodalMain">
      <div className="deleteView">
        <div className="infoView">
          <p>{!props.isStatusModal ? "Are you sure you want to delete" : props.statusText}
            <span> {deletItemName}</span>
          </p>
        </div>
        <div className="btnGroup">
          <CustomButton className="redBtn"
            title={!props.isStatusModal ? "Delete" : props.statusTitle}
            onClick={() => { props.onClick(props._id) }}
          />
          <CustomButton className="cancelBtn"
            onClick={closeClick}
            title="Cancel" />
        </div>
      </div>
    </div>
  );
}

Deletemodal.defaultProps = {
  // deletItemName:'' 
}
export default Deletemodal;
