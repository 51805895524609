import axios from './axios';
import Constants from './constant';

export default class WebService {

    static async postWebServices(URL, params, callback) {
        try {
            await axios.post(Constants.BASE_URL + URL, params).then(res => {
                callback(res);
            })
        } catch (error) {
            callback(error);
        }
    }

    static async getWebServices(URL, params, callback) {

        try {
            await axios.get(Constants.BASE_URL + URL, {
                params: params
            }).then(res => {
                callback(res);
            })
        } catch (error) {
            callback(error);
        }
    }

    static async putWebServices(URL, params, callback) {
        try {
            await axios.put(Constants.BASE_URL + URL, params).then(res => {
                callback(res);
            })
        } catch (error) {
            callback(error)
        }
    }

    static async deleteWebServices(URL, params, callback) {
        try {
            await axios.delete(Constants.BASE_URL + URL, params).then(res => {
                callback(res);
            })
        } catch (error) {
            callback(error);
        }
    }

}

export const requestConfig = axios.interceptors.request.use(
    config => {
        const userData = JSON.parse(localStorage.getItem("KeyUser"));
        config.headers.Authorization = userData ? userData.token : "";
        return config;
    },
    error => Promise.reject(error)
);