import React from 'react';
import './Nodata.scss';


const Nodata = (props) => {
  let { centerTxt } = props
  return (
    <div className="emptyData">
      <span>{centerTxt}</span>
    </div>
  )
}

export default (Nodata);
