import React from 'react';
import './dashboard.scss';
import shield from '../../assets/images/shield_img.png';
import faces from '../../assets/images/faces_img.png';
import Header from '../../components/Header/Header';


class Dashboard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showHidePassword: false,
      showPass: false,
      toast: {
        show: false,
        type: "",
        msg: "",
        errorToast: false
      },
      width: window.innerWidth, height: window.innerHeight,
      index: 0
    };
  }

  onKeyDown(e) {
    e.keyCode === 13 && this.onFormSubmit()
  }
  onFormSubmit = () => {
    this.setState({
      toast: {
        show: true,
        type: "Success",
        msg: "You have successfully logged in!!",
        errorToast: false
      }
    })

    setTimeout(() => {
      this.setState({
        toast: {
          show: false,
          msg: "",
          errorToast: false
        }
      })
      this.props.history.push('/dashboard')
    }, 2000);
  }
  handleSelect(selectedIndex, e) {
    this.setState({
      index: selectedIndex,
      direction: e.direction,
    });
  }

  render() {
    return (
      <div fluid className="dashboardPage">
        <Header {...this.props} />
        <div className='innerPage'>
          <div className="ovalyellowSec">
            <span className="whiteTxt">HOME</span>
          </div>
          <div className="mainSec">

            <div className="centerBigSec">
              <a className="whiteSec" onClick={()=>{
                this.props.history.push('/clubs')
              }}>
                <div className="imgSec">
                  <img src={shield} className="" alt="" />
                </div>
                <div className="txtSec">
                  <h2>CLUBS</h2>
                  <a className="nextBtn" >
                    <i className="icon-right-arrow"></i>
                  </a>
                </div>
              </a>
              <a className="whiteSec" onClick={()=>{
                this.props.history.push('/users')
              }}>
                <div className="imgSec">
                  <img src={faces} className="" alt="" />
                </div>
                <div className="txtSec">
                  <h2>USERS</h2>
                  <a className="nextBtn" >
                    <i className="icon-right-arrow"></i>
                  </a>
                </div>
              </a>
            </div>

          </div>
          <span className="footerTxt">Copyright © 2020 Community Club Initiatives</span>
        </div>

      </div >
    )
  }
}

export default (Dashboard);

