import React, { useContext } from 'react';
import './Clubmodal.scss';
import TextField from '../TextField/TextField';
import Constant from '../../util/constant';
import HookForm from '../HookForm/HookForm';
import CustomButton from '../../components/CustomButton/button';
import CustomDropzone from '../../components/CustomDropzone/CustomDropzone';
import { Controller } from 'react-hook-form';
import MainContext from '../../MainContext';
import Spinner from 'react-bootstrap/Spinner'

const Clubmodal = (props) => {
  let {
    closeClick,
    clubNamePlaceholder,
    leagueNamePlaceholder,
    leagueUrlPlaceholder,
    leagueModal,
    onSubmit,
    clubExistsError,
    defaultForm
  } = props;
  let addForm = {};
  console.log(props,'PROPS');
  // let defaultForm = {}
  const { isButtonLoading } = useContext(MainContext);

  if (!leagueModal) {
    addForm = {
      sClubName: {
        name: 'sClubName',
        validate: {
          required: {
            value: true,
            message: "Club Name can't be empty"
          },
          pattern: {
            value: Constant.REGEX.OnlySpaces,
            message: "Club Name can't be empty"
          },
        },
      },
      sLogo: {
        name: 'sClubLogo',
        validate: {
          required: {
            value: true,
            message: "Please select club logo"
          },
        },
      },
      oClubId: {
        name: 'oClubId',
        validate: {
          required: {
            value: true,
            message: "No club id"
          },
        },
      }
    }
  } else if (leagueModal) {
    // if (props._id !== '') {
    //   defaultForm = { 'sFixturesLogo': sFixturesLogo, 'sFixturesURL': sFixturesURL, 'sFixturesTitle': sFixturesTitle }
    // }
    addForm = {
      sFixturesTitle: {
        name: 'sFixturesTitle',
        validate: {
          required: {
            value: true,
            message: "League Title can't be empty"
          },
          pattern: {
            value: Constant.REGEX.OnlySpaces,
            message: "League Title can't be empty"
          },
        },
      },
      sLeagueUrl: {
        name: 'sFixturesURL',
        validate: {
          required: {
            value: true,
            message: "League URL can't be empty"
          },
          pattern: {
            value: Constant.REGEX.OnlySpaces,
            message: "League URL can't be empty"
          },
          validate: {
            hasValidUrl: (value) => (value && value.match(Constant.REGEX.Url)) || 'Invalid Url',
          },
        },
      },
      sLogo: {
        name: 'sFixturesLogo',
      }
    }
  }
  return (
    <div className="clubmodalMain">
      <HookForm
        defaultForm={defaultForm}
        onSubmit={(formMethod) => onSubmit(formMethod)}>
        {(formMethod) => {
          return (
            <div className="formSec">
              <Controller
                name={addForm.sLogo.name}
                control={formMethod?.control}
                rules={addForm.sLogo.validate}
                errors={formMethod?.errors}
                as={<CustomDropzone {...props} type="clubDropzone" />}
              />
              {!leagueModal ? <><TextField
                formMethod={formMethod}
                register={formMethod?.register(addForm.sClubName.validate)}
                name={addForm.sClubName.name}
                errors={formMethod?.errors}
                customError={{
                  name: addForm.sClubName.name,
                  message: clubExistsError,
                }}
                autoFocus={true}
                type="text"
                placeholder={clubNamePlaceholder}
              />
              </>
                : <>
                  <TextField
                    formMethod={formMethod}
                    register={formMethod?.register(addForm.sFixturesTitle.validate)}
                    name={addForm.sFixturesTitle.name}
                    errors={formMethod?.errors}
                    autoFocus={true}
                    type="text"
                    placeholder={leagueNamePlaceholder}
                  />
                  <TextField
                    formMethod={formMethod}
                    register={formMethod?.register(addForm.sLeagueUrl.validate)}
                    name={addForm.sLeagueUrl.name}
                    errors={formMethod?.errors}
                    autoFocus={true}
                    type="text"
                    placeholder={leagueUrlPlaceholder}
                  />
                </>

              }

              <div className="btnGroup">
                <CustomButton type="submit"
                  title={!isButtonLoading ? props._id !== '' ? 'Update' : 'Add' : ''}
                  disabled={!formMethod?.formState.isValid || isButtonLoading}
                >
                  {isButtonLoading && <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />}
                </CustomButton>
                <CustomButton
                  className="cancelBtn" onClick={closeClick}
                  title="Cancel" />
              </div>
            </div>
          )
        }}
      </HookForm>
    </div>
  );
}
Clubmodal.defaultProps = {
  leagueModal: false,
  clubExistsError: ""
}

export default Clubmodal;
